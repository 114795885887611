
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DoughnutChart from '@/lib/charts/doughnutChart';
import gptModule from '@/store/modules/gptModule';
import axiosClient from '@/lib/rest/axiosClient';
import GptMixin from '@/components/gpt/GptMixin';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    DoughnutChart,
  },
})
export default class LastActionSection  extends mixins(GptMixin) {
  @Prop({ type: String, required: true, default: '' }) gptTextQuery?: any;
  @Prop({ type: Object, required: true }) tasq?: any;

  recentLastActions: any = [];

  get recentLastActionsPayload() {
    return gptModule.defaultLastActions;
  }

  get loadingRecentActions() {
    return gptModule.isLoadingActions;
  }

  showAllItems = false;

  updateToggle() {
    this.showAllItems = !this.showAllItems;
  }

  get currentlyPendingLastActionComment() {
    return gptModule.currentlyPendingLastActionComment;
  }

  get completedTodoItems(){
     return gptModule.completedTodoItems.map((i) => {
      return {
        ...i,
        title: 'To do item',
        type: 'To do item',
        body: i.text,
        color: 'bg-accentSuccess200',
        dateTime: i.date ? new Date(i.date).getTime(): i.updatedAt,
        dateText: i.date ? this.formatDate(i.date): this.formatDate(i.updatedAt)
      }
     })
  }

  get lastActionsList() {
    let lengthOfActions = this.currentlyPendingLastActionComment.length ? 2 : 3;

    if (this.showAllItems) {
      lengthOfActions = 100;
    }
    let sortedActions = [];

    if (
      this.lastActions &&
      this.lastActions.length &&
      this.gptTextQuery &&
      this.gptTextQuery.length > 2
    ) {
      sortedActions = this.lastActions.sort((a, b) => b.dateTime - a.dateTime);
    } else {

      const combineLastActions = [...this.completedTodoItems, ...this.recentLastActions]
      sortedActions = combineLastActions.sort(
        (a, b) => b.dateTime - a.dateTime
      );
    }

    const data = sortedActions
      .slice(0, lengthOfActions)
      .sort((a, b) => a.dateTime - b.dateTime);
    gptModule.setCurrentlyViewedActions(data);
    return data;
  }

  get lastActions(): any {
    const actions = gptModule.lastActions;
    return this.formatLastActions(actions);
  }

  @Watch('recentLastActionsPayload', { immediate: true })
  onUpdateLastActions() {
    this.recentLastActions = this.formatLastActions(
      this.recentLastActionsPayload
    );
  }

  formatLastActions(actions) {
    return actions.map((action) => {
      return {
        ...action,
        title: action.type,
        summary: action.summary,
        dateText:
          action.type.toLowerCase() === 'waiting on' && action.start_date
            ? this.formatDate(action.start_date)
            : this.formatDate(action.date),
        color: this.mapLastActionColor(action.type),
        date: new Date(action.date),
        dateTime:
          action.type.toLowerCase() === 'waiting on' && action.start_date
            ? new Date(action.start_date).getTime()
            : new Date(action.date).getTime(),
        endDate: action.end_date ? new Date(action.end_date) : null,
        prePost: Math.round(action.pre_post * 100),
        reason: action.reason,
        category: action.category,
        subCategory: action.sub_category,
        fixedCategory: action.fixed_category,
        cause: action.cause,
        trackingId: action.tracking_id,
        nodeID: action.nodeid,

        // dateText: this.formatDateRange(
        //   action.startDateTime,
        //   action.endDateTime
        // ),
      };
    });
  }



  mapLastActionColor(type) {
    const localType = type.toLowerCase();
    if (localType === 'on site fix') {
      return `bg-onsitefix`;
    } else if (localType === 'vendor') {
      return `bg-vendor`;
    } else if (localType === 'setpoint change' || localType === 'changepoint') {
      return `bg-setpointchange`;
    } else if (localType === 'changepoint') {
      return `bg-setpointchange`;
    } else if (localType === 'waiting on') {
      return `bg-waitingOn`;
    } else if (localType === 'midstream') {
      return `bg-midstream`;
    } else if (localType === 'alarm') {
      return `bg-alarm`;
    }
    return `bg-tasqNeutral300`;
  }

  formatDate(date) {
    const options: any = { month: 'short', day: 'numeric', year: 'numeric' };

    let dateObj = new Date(date);

    let startFormatted = dateObj.toLocaleDateString('en-US', options);

    return `${startFormatted}`;
  }

  async created(){
    this.getGptLastActions()
      .then(() => {})
      .catch(() => {
        gptModule.setActionsLoading(false);
      });
  }
  async getGptLastActions() {
    gptModule.setActionsLoading(true);
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );
    const metaData = {
      useAll: false,
      wellMetaData: {
        ...(this.operatorName !== 'demo' && {
          operator_name: operatorDetails['operatorName'],
        }),
        ...(this.operatorName !== 'demo' && {
          operator_id: operatorDetails['operatorID'],
        }),
        nodeid: this.tasq.wellName,
        level:
          this.tasq && this.tasq.level ? this.tasq.level.toLowerCase() : 'well',
      },
    };

    gptModule.resetLastActions();

    const response = await axiosClient.post('/ai/actions', {
      query: '',
      stream: false,
      ...metaData,
    });

    const { results } = response.data;
    const comments = results['comments'];
    const alarms = results['alarms'];
    const changepoints = results['changepoints'];

    const recentLastActions = [...comments, ...alarms, ...changepoints];
    gptModule.setDefaultLastActions(recentLastActions);
    gptModule.setActionsLoading(false);
  }
}
